import React from 'react';

const DocumentSection = () => {
    return (
        <>
            <section className="capabilities__area p-relative black-bg-2 pt-60 pb-60 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/home-bg1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-50">
                                    <h2 className="white-color">Following Documents are provided with your Office Space Service</h2>
                                </div>
                                <div className="capabilities__list">
                                    <ol>
                                        <li>No Objection Certificate (NOC)</li>
                                        <li>Utility Bill</li>
                                        <li>Agreement</li>
                                        <li>KYC and Compliance Documents</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DocumentSection;