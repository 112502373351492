import React from 'react';

const ClientSingle = ({ img_1, alt_1 }) => {
    return (
        <>
            <div className="brand__item-wrapper">
                <div className="brand__item r-img">
                    <img src={`assets/img/clients/${img_1}.jpg`} alt={`${alt_1}`} />
                </div>
            </div>
        </>
    );
};

export default ClientSingle;