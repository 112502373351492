import React from 'react';
import { Link } from 'react-router-dom';

const ContactSection = () => {
    return (
        <>
            <section className="faq__area pt-60 pb-60 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="faq__accordion">
                                <div className="accordion" id="accordionExample" >
                                    <div className="card accordion-item">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    What exactly is a Office Space and who can benefit from it?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>A Office Space offers the advantage of having an office space without the need for physical ownership or payment. It provides a cost-effective solution for individuals seeking to rent an office space for various purposes such as starting or expanding their business in a different city, managing a remote business, or handling stocks and supplies across different states in Raipur.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    What are the different types or applications of a Office Space?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>A Office Space can serve as a mailing address, enabling you to access all the necessary services for official representation. It can also be utilized for GST registration, facilitating the acquisition of a GST number and registration in new states. Additionally, Office Spaces are commonly employed for company registration purposes in different cities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    What are the essential documents required to acquire a Office Space?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>A Office Space presents a legitimate resolution for addressing administrative challenges in various states for your business. At Premisin, you can gain access to certified and top-notch office locations, providing the opportunity to run your business virtually. Simply select your desired office address, and our team will take care of the setup process on your behalf.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    How does Premisin ensure the secure and high-quality management of my Office Space?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Premisin assists numerous businesses in operating their Office Spaces nationwide. In addition to offering the necessary support and representation your company deserves, we ensure the prompt delivery of essential documents, such as rent agreements, utility bills, and No Objection Certificates (NOC) from the landlord, typically within an average timeframe of 72 hours.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="contact__wrapper">
                                <div className="m-img">
                                    <Link to="/contact">
                                        <img src="assets/img/contact-img1.jpg" alt="sjain" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactSection;