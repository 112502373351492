import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/Helper/ScrollTop";
import BackToTop from "./components/Helper/BackToTop";
import Error from './components/Error';
import Home from './components/Home/index';
import About from './components/About/index';
import Contact from './components/Contact/index';

import Raipur from './components/Raipur/index';

function App() {
    return (
        <>
            <Router>
                <ScrollTop />
                <Routes>
                    <Route path="*" element={<Error />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/raipur/virtual-office" element={<Raipur />} />
                </Routes>
                <BackToTop />
            </Router>
        </>
    );
}

export default App;
