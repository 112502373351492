import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import useGlobalContext from '../../hooks/useGlobalContext';
// import { FaFacebookF, FaTwitter, FaPhoneAlt, FaEnvelope, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Header = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { stickyMenu } = useGlobalContext();

    return (
        <>
            <header>
                <div className="header__area">
                    {/* <div className="header__top d-none d-md-block">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-5 col-md-4">
                                    <div className="header__social">
                                        <ul>
                                            <li><a href="https://www.facebook.com/premisin/" target="_blank" rel="noreferrer"><i><FaFacebookF /></i></a></li>
                                            <li><a href="https://twitter.com/premisin/" target="_blank" rel="noreferrer"><i><FaTwitter /></i></a></li>
                                            <li><a href="https://www.instagram.com/premisinraipur/" target="_blank" rel="noreferrer"><i><FaInstagram /></i></a></li>
                                            <li><a href="https://www.linkedin.com/in/premisin/" target="_blank" rel="noreferrer"><i><FaLinkedinIn /></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7 col-md-8">
                                    <div className="header__info f-right">
                                        <ul>
                                            <li><a href="tel:7583837705"><i><FaPhoneAlt /></i>758383 7705</a></li>
                                            <li><a href="mailto:info@premisin.com"><i><FaEnvelope /></i>info@premisin.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div id="header__sticky" className={stickyMenu ? "sticky header__bottom header__bottom-2" : "header__bottom header__bottom-2"}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="logo-3">
                                        <NavLink to="/">
                                            <img src="assets/img/logo.png" alt="sjain" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                                    <div className="header__bottom-right d-flex justify-content-end align-items-center">
                                        <div className="main-menu main-menu-3 menu_three_wrapper">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li>
                                                        <NavLink to="/">Home</NavLink>
                                                    </li>
                                                    <li><NavLink to="/raipur/virtual-office">Raipur</NavLink></li>
                                                    <li><NavLink to="/about">About Us</NavLink></li>
                                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                                            <Link to="/contact" className="z-btn z-btn-3">Get a Quote</Link>
                                        </div>
                                        <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                            <div className="sidebar-toggle-btn sidebar-toggle-btn-3" id="sidebar-toggle">
                                                <span className="line"></span>
                                                <span className="line"></span>
                                                <span className="line"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

            <Sidebar show={show} handleClose={handleClose} />
        </>
    );
};

export default Header;