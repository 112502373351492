import Slider from "react-slick";
import SingleBrandItem from "./ClientSingle";

const ClientSection = () => {
    // slick setting
    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]

    };
    return (
        <>
            <section className="brand__area pt-60 pb-60 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Our Clients</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Slider className='brand-active' {...settings}>
                            <SingleBrandItem img_1="1" alt_1="vodafone idea - vi - client sjain office space" />
                            <SingleBrandItem img_1="2" alt_1="piramal foundation - client sjain office space" />
                            <SingleBrandItem img_1="3" alt_1="piramal swasthya - client sjain office space" />
                            <SingleBrandItem img_1="4" alt_1="reliance commercial finance - client sjain office space" />
                            <SingleBrandItem img_1="5" alt_1="uber india - client sjain office space" />
                            <SingleBrandItem img_1="6" alt_1="livspace - client sjain office space" />
                            <SingleBrandItem img_1="7" alt_1="quick heal - client sjain office space" />
                            <SingleBrandItem img_1="8" alt_1="car dekho - client sjain office space" />
                            <SingleBrandItem img_1="9" alt_1="zte - client sjain office space" />
                            <SingleBrandItem img_1="10" alt_1="aramex - client sjain office space" />
                            <SingleBrandItem img_1="11" alt_1="stl tech - client sjain office space" />
                            <SingleBrandItem img_1="12" alt_1="phone pe - client sjain office space" />
                            <SingleBrandItem img_1="13" alt_1="hitachi energy - client sjain office space" />
                            <SingleBrandItem img_1="14" alt_1="digit insurance - client sjain office space" />
                            <SingleBrandItem img_1="15" alt_1="zomato - client sjain office space" />
                            <SingleBrandItem img_1="16" alt_1="byju's - client sjain office space" />
                            <SingleBrandItem img_1="17" alt_1="runh power - client sjain office space" />
                            <SingleBrandItem img_1="18" alt_1="quess corp - client sjain office space" />
                            <SingleBrandItem img_1="19" alt_1="sbi card - client sjain office space" />
                            <SingleBrandItem img_1="20" alt_1="urban company - client sjain office space" />
                        </Slider>
                    </div>
                </div>
            </section>

        </>
    );
};

export default ClientSection;