import React from 'react';
import { Link } from 'react-router-dom';
import HubspotForm from 'react-hubspot-form'

const HeroSection = () => {
    return (
        <>
            <section className="hero__area black-bg-2 pt-80 pb-60">
                <div className="hero__item">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 offset-lg-1 order-lg-last">
                                <div className="contact__home fix">
                                    <div className="contact__info">
                                        <h3>Get Your Office Quote Now!</h3>
                                    </div>
                                    <div className="contact__form home">
                                        <HubspotForm
                                            portalId="7848680"
                                            formId="cf71a51e-2ec0-4e62-a181-7c1ef898b72b"
                                            onSubmit={() => console.log('Submit!')}
                                            onReady={(form) => console.log('Form ready!')}
                                            loading={<div>Loading...</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="hero__content">
                                    <h1 className="white-color">Sjain - Find Your Perfect Office Space</h1>
                                    <h2 className="white-color">Flexible Office Rentals for Every Business</h2>
                                    <p className="white-color">Discover flexible, fully-equipped office spaces designed to meet your business needs. From small startups to growing enterprises, we offer tailored solutions in prime locations. Explore modern office spaces with premium amenities, scalable to suit your team. Whether you need a single desk or an entire floor, we've got you covered with customizable rental terms. Upgrade to an office that supports your success. From coworking spaces to private offices, our properties are designed to foster creativity and collaboration in the heart of the city.</p>
                                    <Link to="/contact" className="z-btn z-btn-white z-btn-white-3">Enquire Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroSection;