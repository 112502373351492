import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import HubspotForm from 'react-hubspot-form'
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const today = new Date();

const Footer = () => {
    const [modalToShow, setModalToShow] = useState(null);
    const handleClose = () => setModalToShow(null);
    const handleShow = (modalId) => setModalToShow(modalId);

    return (
        <>
            <footer>
                <div className="footer__area">
                    <div className="footer__copyright-3">
                        <div className="container">
                            <div className="footer__copyright-inner">
                                <div className="row align-items-center">
                                    <div className="col-xl-12">
                                        <div className="footer__copyright-text text-center">
                                            <p>Sjain Office Space &copy; {today.getFullYear()} - All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="rightBox">
                <a href="tel:7583837705"><i><FaPhoneAlt /></i></a>
                <Link to="/" id="clickFrom" onClick={() => handleShow('ModalOne')}><i><FaEnvelope /></i></Link>
            </div>
            <Modal show={modalToShow === 'ModalOne'} onHide={handleClose} backdrop="static" keyboard={false} centered className="modelDsider">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 className="mb-0">Get Your Office Quote Now!</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contact__home fix">
                        <div className="contact__form home">
                            <HubspotForm
                                portalId="7848680"
                                formId="cf71a51e-2ec0-4e62-a181-7c1ef898b72b"
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Footer;