import React from 'react';
import { FcRating, FcDocument, FcFlowChart, FcIdea } from "react-icons/fc";

const AssuranceSection = () => {
    return (
        <>
            <section className="achievement__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="achievement__content">
                                <div className="section__title section__title-3 mb-20">
                                    <h2>What assurances does Sjain provide with regards to your Office Space?</h2>
                                </div>
                                <p>We aim to simplify your office search process, ensuring a hassle-free experience. Rely on our knowledgeable workspace solution experts to assist you in finding precisely what you require.</p>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="row">
                                <div className="col-xl-7 col-6">
                                    <div className="achievement__thumb m-img r-img s-img pl-30">
                                        <img src="assets/img/home-img2.jpg" alt="sjain" />
                                    </div>
                                </div>
                                <div className="col-xl-5 col-6">
                                    <div className="achievement__thumb w-img r-img s-img">
                                        <img src="assets/img/home-img3.jpg" alt="sjain" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-40">
                        <div className="col-xl-12">
                            <div className="achievement__content">
                                <div className="achievement__wrapper d-block d-lg-flex justify-content-between">
                                    <div className="achievement__item mt-30">
                                        <i><FcRating /></i>
                                        <h3>Best Price Guaranteed</h3>
                                        <p>We provide the best price around all our Partnered location with best service guaranteed.</p>
                                    </div>
                                    <div className="achievement__item mt-30">
                                        <i><FcDocument /></i>
                                        <h3>Quick Document Processing & Completion</h3>
                                        <p>Our document delivery time averages between 5 to 7 working days.</p>
                                    </div>
                                    <div className="achievement__item mt-30">
                                        <i><FcFlowChart /></i>
                                        <h3>Wide Partners Network</h3>
                                        <p>Benefit from our extensive network of partner spaces across Raipur, allowing you to own a office space in any major city.</p>
                                    </div>
                                    <div className="achievement__item mt-30">
                                        <i><FcIdea /></i>
                                        <h3>Seamless Experience</h3>
                                        <p>Experience a 100% digital process that eliminates the need for running around to fulfill compliances or engage in unnecessary calls.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AssuranceSection;