import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeroSection from './HeroSection';
import PlanSection from './PlanSection';
import ChoiceSection from './ChoiceSection';
import DocumentSection from './DocumentSection';
import AssuranceSection from './AssuranceSection';
import ProcessSection from './ProcessSection';
import ClientSection from './ClientSection';
import TestimonialSection from './TestimonialSection';
import ContactSection from './ContactSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Sjain Office Space - Office Space For Rent in Raipur" />
            <Header />
            <HeroSection />
            <PlanSection />
            <ChoiceSection />
            <DocumentSection />
            <AssuranceSection />
            <ProcessSection />
            <ClientSection />
            <TestimonialSection />
            <ContactSection />
            <Footer />
        </>
    );
};

export default Home;