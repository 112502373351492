import React from 'react';
import { MdRocketLaunch, MdShoppingCart, MdAutoGraph } from "react-icons/md";

const ChoiceSection = () => {
    return (
        <>
            <section className="features__area grey-bg-12 pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="features__content-left">
                                <div className="section__title section__title-3">
                                    <h2>Is a Office Space the appropriate choice for your needs?</h2>
                                </div>
                                <div className="m-img r-img s-img mt-50">
                                    <img src="assets/img/home-img1.jpg" alt="sjain" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 offset-xl-1 col-lg-6">
                            <div className="features__content-right">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="features__item features__item-2 white-bg fix mb-30">
                                            <div className="features__thumb-2" style={{ background: `url(assets/img/home-hover-img1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
                                            <div className="features__content-2">
                                                <div className="features__icon features__icon-2">
                                                    <i><MdRocketLaunch /></i>
                                                </div>
                                                <div>
                                                    <h3>Early Age Startups & SMEs</h3>
                                                    <p>Entrepreneurs and small businesses aiming to register their company in a desirable location.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="features__item features__item-2 white-bg fix mb-30">
                                            <div className="features__thumb-2" style={{ background: `url(assets/img/home-hover-img2.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
                                            <div className="features__content-2">
                                                <div className="features__icon features__icon-2">
                                                    <i><MdShoppingCart /></i>
                                                </div>
                                                <div>
                                                    <h3>Online Ecommerce Sellers</h3>
                                                    <p>Online retailers looking to obtain GST registration in any state to designate it as their Additional Place of Business (APOB) or Principal Place of Business (PPOB).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="features__item features__item-2 white-bg fix">
                                            <div className="features__thumb-2" style={{ background: `url(assets/img/home-hover-img3.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
                                            <div className="features__content-2">
                                                <div className="features__icon features__icon-2">
                                                    <i><MdAutoGraph /></i>
                                                </div>
                                                <div>
                                                    <h3>Enterprises seeking opportunities for growth and expansion</h3>
                                                    <p>Effortlessly expand your business without concerns regarding mail management.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChoiceSection;