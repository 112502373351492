import React from 'react';
import Slider from "react-slick";

const TestimonialSection = () => {
    // slick setting
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        fade: false,
        arrows: false,

    }
    const testimonialData = [
        {
            id: 1,
            name: 'Nikunj Jain',
            title: 'Review on Google Business',
            disc: 'Good place to have meetings and all. Well designed which provides great convenience. Also has good parking facilities.',
        },
        {
            id: 2,
            name: 'Gaurav Chandrakar',
            title: 'Review on Google Business',
            disc: 'I have visited many places but this place is best as compared to others for meetings and conferences. You should also try it.',
        },
        {
            id: 2,
            name: 'Vignesh Jeyachandran',
            title: 'Review on Google Business',
            disc: 'Best co worker space in raipur. All facilities provided, highly recommend.',
        },
        {
            id: 2,
            name: 'Ashish Bajaj',
            title: 'Review on Google Business',
            disc: 'Visit their office, you will surely love it. A great place to work. Neat, well furnished, and peaceful so as to stimulate the flow of creativity.',
        },
    ]

    return (
        <>
            <section className="testimoinal__area gradient-bg pt-60 pb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-6">
                            <div className="testimonial__content">
                                <div className="section__title section__title-3 mb-40">
                                    <h2 className="white-color">Testimonials</h2>
                                </div>
                                <Slider className='testimonial__slider pb-80' {...settings}>
                                    {
                                        testimonialData.map((testimonial, index) => {
                                            return <div key={index} className="testimonial__item">
                                                <p className="white-color">{testimonial.disc}</p>
                                                <div className="testimonial__content d-flex align-items-center">
                                                    <div className="quote mr-20">
                                                        <img src="assets/img/quote.png" alt="Premisin" />
                                                    </div>
                                                    <div className="testimonial__info">
                                                        <h4 className="white-color">{testimonial.name}</h4>
                                                        <span className="white-color">{testimonial.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6">
                            <div className="testimonial__thumb m-img text-end">
                                <img src="assets/img/testimonial-img1.png" alt="sjain" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TestimonialSection;