import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <>
            <section className="errorArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="errorBox">
                                <h1>
                                    4<span>0</span>4
                                </h1>
                                <h2>oops... page not found</h2>
                                <Link to="/" className="z-btn mt-40">
                                    Back To Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Error;