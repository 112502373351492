import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";

const PlanSection = () => {
    return (
        <>
            <section className="price__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Solutions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="expart__wrapper">
                                <div className="expart__nav">
                                    <ul className="nav nav-pills justify-content-start" id="expart-tab" role="tablist">
                                        <li className="nav-item text-center">
                                            <a className="nav-link active" id="company-tab" data-bs-toggle="pill" href="#company" role="tab" aria-controls="company" aria-selected="true">
                                                New Company Registration Plan
                                            </a>
                                        </li>
                                        <li className="nav-item text-center">
                                            <a className="nav-link" id="gst-tab" data-bs-toggle="pill" href="#gst" role="tab" aria-controls="gst" aria-selected="false">
                                                GST Registration Plan
                                            </a>
                                        </li>
                                        <li className="nav-item text-center">
                                            <a className="nav-link" id="business-tab" data-bs-toggle="pill" href="#business" role="tab" aria-controls="business" aria-selected="false">
                                                Business Address Plan
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="expart__tab">
                                    <div className="tab-content" id="expart-Content">
                                        <div className="tab-pane fade show active" id="company" role="tabpanel" aria-labelledby="company-tab">
                                            <div className="expart__tab-content white-bg">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="expart__content">
                                                            <h3>New Company Registration Plan</h3>
                                                            <div className="price__features">
                                                                <b className="d-block mb-10">Ideal for</b>
                                                                <ul>
                                                                    <li><span>New Company Registration Address Proof</span></li>
                                                                    <li><span>New GST Registration, APOB and PPOB Registration</span></li>
                                                                    <li><span>Mailing Address for Courier Handling, Address on Website, Google, Business Card, letterhead etc</span></li>
                                                                </ul>
                                                            </div>
                                                            {/* <div className="price__doc mt-20 mb-40">
                                                                <b className="d-block mb-10">Documents to be Provided</b>
                                                                <ul>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> Agreement Draft
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> No Objection Certificate (NOC)
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> Utility Bill
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div> */}
                                                            <div className="price__doc mt-20 mb-40"></div>
                                                            <div className="row align-items-center">
                                                                <div className="col-xl-6">
                                                                    <div className="price__features mb-10">
                                                                        <b className="d-block">Tenure - 11 Months</b>
                                                                    </div>
                                                                    <div className="price__tag">
                                                                        <div className="d-flex flex-wrap">
                                                                            <span className="start">Starting at</span>
                                                                            <h1><i><MdOutlineCurrencyRupee /></i>25000 + GST</h1>
                                                                            <span>/month</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <div className="text-lg-end mt-lg-0 mt-30">
                                                                        <Link to="/contact" className="z-btn">Book Now</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="gst" role="tabpanel" aria-labelledby="gst-tab">
                                            <div className="expart__tab-content white-bg">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="expart__content">
                                                            <h3>GST Registration Plan</h3>
                                                            <div className="price__features">
                                                                <b className="d-block mb-10">Ideal for</b>
                                                                <ul>
                                                                    <li><span>New GST Registration</span></li>
                                                                    <li><span>Principal Place of Business (PPOB) Registration</span></li>
                                                                    <li><span>Additional Place of Business (APOB) Registration</span></li>
                                                                    <li><span>Mailing Address for Courier Handling, Address on Website, Google, Business Card, letterhead etc</span></li>
                                                                </ul>
                                                            </div>
                                                            {/* <div className="price__doc mt-20 mb-40">
                                                                <b className="d-block mb-10">Documents to be Provided</b>
                                                                <ul>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> Agreement Draft
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> No Objection Certificate (NOC)
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> Utility Bill
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div> */}
                                                            <div className="price__doc mt-20 mb-40"></div>
                                                            <div className="row align-items-center">
                                                                <div className="col-xl-6">
                                                                    <div className="price__features mb-10">
                                                                        <b className="d-block">Tenure - 11 Months</b>
                                                                    </div>
                                                                    <div className="price__tag">
                                                                        <div className="d-flex flex-wrap">
                                                                            <span className="start">Starting at</span>
                                                                            <h1><i><MdOutlineCurrencyRupee /></i>21000 + GST</h1>
                                                                            <span>/month</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <div className="text-lg-end mt-lg-0 mt-30">
                                                                        <Link to="/contact" className="z-btn">Book Now</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                                            <div className="expart__tab-content white-bg">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="expart__content">
                                                            <h3>Business Address Plan</h3>
                                                            <div className="price__features">
                                                                <b className="d-block mb-10">Ideal for</b>
                                                                <ul>
                                                                    <li><span>Mailing Address for Courier Handling</span></li>
                                                                    <li><span>Mailing Address on Website</span></li>
                                                                    <li><span>Business Address Google My Business Page</span></li>
                                                                    <li><span>Mailing address Business Card, letterhead, Brochure etc</span></li>
                                                                </ul>
                                                            </div>
                                                            {/* <div className="price__doc mt-20 mb-40">
                                                                <b className="d-block mb-10">Documents to be Provided</b>
                                                                <ul>
                                                                    <li>
                                                                        <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noreferrer">
                                                                            <i><MdAttachFile /></i> Agreement Draft
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div> */}
                                                            <div className="price__doc mt-20 mb-40"></div>
                                                            <div className="row align-items-center">
                                                                <div className="col-xl-6">
                                                                    <div className="price__features mb-10">
                                                                        <b className="d-block">Tenure - 11 Months</b>
                                                                    </div>
                                                                    <div className="price__tag">
                                                                        <div className="d-flex flex-wrap">
                                                                            <span className="start">Starting at</span>
                                                                            <h1><i><MdOutlineCurrencyRupee /></i>18000 + GST</h1>
                                                                            <span>/month</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <div className="text-lg-end mt-lg-0 mt-30">
                                                                        <Link to="/contact" className="z-btn">Book Now</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PlanSection;