import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeadSection from './HeadSection';
import AboutUs from './AboutUs';
import WhatWeDo from './WhatWeDo';
import ContentSection from './ContentSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Premisin Virtual Office - Discover Our Virtual Office Services" />
            <Header />
            <HeadSection />
            <AboutUs />
            <WhatWeDo />
            <ContentSection />
            <Footer />
        </>
    );
};

export default Home;