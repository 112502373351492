import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';

const images = [
    'https://www.premisin.com/assets/images/location/2023/kamal-vihar/2.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/7.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/27.jpg',
    'https://www.premisin.com/assets/images/location/2023/bhatagaon/2.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/3.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/4.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/16.jpg',
    'https://www.premisin.com/assets/images/location/2023/vallabh-nagar-2/24.jpg',
];

const PhotoSection = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}

            <section className="portfolio__area pt-60 pb-60 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="portfolioBox">
                                {images.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button onClick={() => openLightbox(index)}>
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PhotoSection;  