import React from 'react';

const FaqSection = () => {
    return (
        <>
            <section className="faq__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <div className="faq__accordion mb-0">
                                <div className="accordion" id="accordionExample" >
                                    <div className="card accordion-item">
                                        <div className="card-header accordion-header" id="acc_1" >
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                    Which companies can use a virtual office for GST registration?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Virtual offices are essential for various companies, including e-commerce vendors, consumer brands with nationwide product distribution, businesses with sales teams spanning the country, and startups establishing themselves in new cities, as they require a virtual office for GST registration.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_2">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    As per the guidelines of the GST Department, what are the requirements for a registered address?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>In addition to the necessary documents, it is also mandatory for the company to fulfill the following requirements:</p>
                                                <ul>
                                                    <li><span>Name Plate inside the office space.</span></li>
                                                    <li><span>Books of Account must be maintained at either the registered address or the principal place of business of the company. The principal place of business refers to the location from which the company conducts its operations.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_3">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                                    Which documents are required to register an entity for a virtual office for company registration?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>To register any entity for virtual office or company registration, the following documents are necessary:</p>
                                                <ul>
                                                    <li><span>Identity proof of all directors.</span></li>
                                                    <li><span>Approval of the company name (if the directors share the same name). If the entity is already registered at a different address and wishes to change addresses, the following additional documents are required:</span>
                                                        <ul>
                                                            <li><span>Certificate of Incorporation of the Company (COI).</span></li>
                                                            <li><span>The Company's Pan Card</span></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="acc_4">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                                    Is the concept of a virtual office considered legal and permissible in India?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                            <div className="card-body accordion-body">
                                                <p>Indeed, virtual offices are recognized as legal entities in India.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqSection;