import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ show, handleClose }) => {

    return (
        <>
            <div>
                <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <section>
                            <div className="p-0">
                                <div className='side_navBar'>
                                    <div className='about iconAdd'>
                                        <NavLink to="/">Home</NavLink>
                                    </div>
                                    <div className='about iconAdd'>
                                        <li><NavLink to="/raipur/virtual-office">Raipur</NavLink></li>
                                    </div>
                                    <div className='about iconAdd'>
                                        <li><NavLink to="/about">About Us</NavLink></li>
                                    </div>
                                    <div className='about iconAdd border-0'>
                                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default Sidebar;