import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';
import HubspotForm from 'react-hubspot-form'

import HeadSection from './HeadSection';
import PhotoSection from './PhotoSection';
import PlanSection from './PlanSection';
import AboutCity from './AboutCity';
import ProcessSection from './ProcessSection';
import FaqSection from './FaqSection';
import ContentSection from './ContentSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Virtual Office Space in Raipur, Rent Modern Business Address" />
            <Header />
            <HeadSection />
            <div className="container">
                <div className="row">
                    <div className="col-xl-8">
                        <PhotoSection />
                        <PlanSection />
                        <AboutCity />
                        <ProcessSection />
                        <FaqSection />
                        <ContentSection />
                    </div>
                    <div className="col-xl-4">
                        <div className="contact__wrapper sticky-top mt-60 fix" style={{ top: '100px', zIndex: '10', padding: '30px' }}>
                            <div className="contact__info">
                                <h3>Get in touch</h3>
                            </div>
                            <div className="contact__form">
                                <HubspotForm
                                    portalId="7848680"
                                    formId="889a4797-9d07-4b26-90c8-5ca03f6f8d44"
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;