import React from 'react';

const ProcessSection = () => {
    return (
        <>
            <section className="services__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>How Process Works</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-12">
                            <div className="services__item d-flex mb-30">
                                <div className="services__icon mr-20">
                                    <img src="assets/img/home-icon2.png" alt="Premisin" />
                                </div>
                                <div className="services__content">
                                    <h3>Select the Plan</h3>
                                    <p>Select the plan which best suits your need and requirement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12">
                            <div className="services__item d-flex mb-30">
                                <div className="services__icon mr-20">
                                    <img src="assets/img/home-icon3.png" alt="Premisin" />
                                </div>
                                <div className="services__content">
                                    <h3>Process the Payment</h3>
                                    <p>Pay the pricing agreed according to the plan.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="services__item d-flex">
                                <div className="services__icon mr-20">
                                    <img src="assets/img/home-icon4.png" alt="Premisin" />
                                </div>
                                <div className="services__content">
                                    <h3>Access your Virtual Office Address</h3>
                                    <p>Sign the Agreement and KYC Process to start using the Office Address.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services__area grey-bg-12 pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Documents Required for KYC</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__content">
                                    <h3>If your company is Incorporated</h3>
                                    <ul>
                                        <li><span>Certificate of Company Incorporation</span></li>
                                        <li><span>GST number (if any)</span></li>
                                        <li><span>Company PAN Card</span></li>
                                        <li><span>Director's (or Trustees) ID proof</span></li>
                                        <li><span>Authorised Signatory ID Proof</span></li>
                                        <li><span>Board Resolution if Signing Authority is other than Director</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__content">
                                    <h3>If your company is not Incorporated</h3>
                                    <ul>
                                        <li><span>Director's (or Trustees)/Individual ID proof</span></li>
                                        <li><span>PAN Card of all the Director's (or Trustees)/Individual</span></li>
                                        <li><span>Planned Company's name (for agreement)</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessSection;