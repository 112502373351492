import React from 'react';

const ProcessSection = () => {
    return (
        <>
            <section className="services__area black-bg-2 pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2 className="white-color">How Process Works</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/home-icon1.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Select the Location</h3>
                                    <p>Choose a location from our network of partnered spaces across Raipur.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/home-icon2.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Select the Plan</h3>
                                    <p>Select the plan which best suits your need and requirement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/home-icon3.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Process the Payment</h3>
                                    <p>Pay the pricing agreed according to the plan.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/home-icon4.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Access your Office Space</h3>
                                    <p>Sign the Agreement and KYC Process to start using the Office Address.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessSection;