import React from 'react';

const AboutCity = () => {
    return (
        <>
            <section className="capabilities__area p-relative black-bg-2 pt-60 pb-60 fix">
                <div
                    className="capabilities__thumb p-absolute"
                    style={{ background: `url(assets/img/about-img1.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="capabilities__content">
                                <div className="section__title section__title-3 mb-25">
                                    <h2 className="white-color">We Offers!</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p className="white-color">We know that the core of all of us is WORK. It has grown to be a part of our lives, not just a job. Premisin simply doesn’t provide you with a co-working space, but we make sure you meet all your needs. For goal-orientated start-ups, innovative bugs, and budding entrepreneurs, we have established a collaborative work area that provides norms. With modern-day facilities, spaces are built to sustain vitality and positive flow. We believe in revolutionizing your space just the way you want, liberating our reach to your comfort. Premisin offers facilities that are spacious, accessible and comfortable for the benefit of our society.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutCity;