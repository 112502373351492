import React from 'react';

const ProcessSection = () => {
    return (
        <>
            <section className="services__area pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <span>What We Do</span>
                                <h2>We do things Differently</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/about-icon1.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Collaborate</h3>
                                    <p>An office optimized for public utilities and collaborative spaces allows you to work and enjoy you work in a stress-free atmosphere.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/about-icon2.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Customize</h3>
                                    <p>Customizing the space according to your own comfort and requirements. We will hear your ideas and implement them your.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="services__item mb-30 mb-lg-0">
                                <div className="services__icon mb-25">
                                    <img src="assets/img/about-icon3.png" alt="sjain" />
                                </div>
                                <div className="services__content">
                                    <h3>Create</h3>
                                    <p>Let success motivate you. Find a picture of what epitomizes success to you and then pull it out when you are in need.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProcessSection;