import React from 'react';

const AboutCity = () => {
    return (
        <>
            <section className="capabilities__area black-bg-2 pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <img src="assets/img/city-raipur.jpg" className="img-fluid mb-50" alt="raipur city - premisin virtual office" />
                            <div className="capabilities__content">
                                <div className="section__title section__title-4">
                                    <h2 className="white-color">About Raipur</h2>
                                </div>
                                <div className="capabilities__list">
                                    <p className="white-color">Raipur, the capital city of Chhattisgarh, has witnessed remarkable business growth in recent years. With a strategic location and favorable policies, Raipur has become a preferred destination for industries and businesses. The city has a strong industrial base, with sectors such as steel, power, cement, and mining playing a significant role in its economy. Raipur also offers excellent connectivity through road, rail, and air networks, facilitating trade and commerce. The government's focus on promoting entrepreneurship and ease of doing business has further fueled business growth in Raipur. The city's vibrant business ecosystem, supportive infrastructure, and access to skilled manpower make it an ideal choice for companies looking to expand and flourish.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutCity;