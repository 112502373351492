import React from 'react';
import HubspotForm from 'react-hubspot-form'

const ContactSection = () => {
    return (
        <>
            <section className="contact__area pt-60 pb-60 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-lg-1">
                            <div className="contact__wrapper fix">
                                <div className="contact__info">
                                    <h3>Get in touch</h3>
                                </div>
                                <div className="contact__form">
                                    <HubspotForm
                                        portalId="7848680"
                                        formId="889a4797-9d07-4b26-90c8-5ca03f6f8d44"
                                        onSubmit={() => console.log('Submit!')}
                                        onReady={(form) => console.log('Form ready!')}
                                        loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactSection;