import React from 'react';

const ContentSection = () => {
    return (
        <>
            <section className="blog__area pt-60 pb-60 grey-bg-12">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="blog__details-wrapper">
                                <div className="blog__text">
                                    <h3>Cost Effective Coworking Space to Build Success.</h3>
                                    <p>Coworking spaces offer you superior flexibility when it comes to providing your personnel with a conducive place to work. You can easily rent a small work space in our shared office for more cost effective business. If you need privacy, we also offer private and executive cabins that promote quiet and committed work.</p>
                                    <p className="mb-0">Our shared office space provides you with the ability to take care of every aspect of your business with our range of amenities. Our fully air conditioned co-working office space is the perfect environment in which you can create ideas, you have all of the comforts of a dedicated office. Our chill zone and fully stocked pantry keeps your personnel happy and comfortable at all times. If you have been asking yourself, where can I find an affordable place to work near me, then <span>PREMISIN</span> is your ideal choice.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default ContentSection;