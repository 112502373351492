import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";

const PlanSection = () => {
    return (
        <>
            <section className="price__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Select a Office Location that aligns with your requirements</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Get Fully Furnished Office Space for Rent in Raipur</p>
                                    <span>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - With Service <i><MdOutlineCurrencyRupee /></i>18,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 200 sq.ft</span></li>
                                            <li><span>Carpet Area - 150 sq.ft</span></li>
                                            <li className="d-none d-lg-block">&nbsp;</li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Book Fully Furnished Office Space for Rent in Raipur</p>
                                    <span>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - Without Service <i><MdOutlineCurrencyRupee /></i>20,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 400 sq.ft</span></li>
                                            <li><span>Carpet Area - 300 sq.ft</span></li>
                                            <li className="d-none d-lg-block">&nbsp;</li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Get Fully Furnished Office Space for Rent in Raipur</p>
                                    <span>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - Without Service <i><MdOutlineCurrencyRupee /></i>22,000/-</span></li>
                                            <li><span>Rental - With Service <i><MdOutlineCurrencyRupee /></i>30,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 800 sq.ft</span></li>
                                            <li><span>Carpet Area - 700 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Fully Furnished Office Space for Rent in Raipur - Ready To Move Available</p>
                                    <span>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>1,10,000/-</span></li>
                                            <li><span>Security Deposit - 5 months</span></li>
                                            <li><span>Built-up Area - 4000 sq.ft</span></li>
                                            <li><span>Carpet Area - 3200 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Book Unfurnished Office Space for Rent in Kamal Vihar, Raipur</p>
                                    <span>Location - Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Unfurnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>40,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 2000 sq.ft</span></li>
                                            <li><span>Carpet Area - 2000 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Book Fully Furnished Office Space for Rent in Kamal Vihar, Raipur</p>
                                    <span>Location - Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>70,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 2000 sq.ft</span></li>
                                            <li><span>Carpet Area - 2000 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Book Unfurnished Office Space for Rent in Kamal Vihar, Raipur</p>
                                    <span>Location - Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Unfurnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>1,00,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 6000 sq.ft</span></li>
                                            <li><span>Carpet Area - 6000 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Book Fully Furnished Office Space for Rent in Kamal Vihar, Raipur</p>
                                    <span>Location - Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>2,00,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 6000 sq.ft</span></li>
                                            <li><span>Carpet Area - 6000 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 active">
                                <div className="price__inner p-relative">
                                    <p>Unfurnished Bareshell Office for Rent in Raipur</p>
                                    <span>Location - 2<sup>nd</sup> Floor, Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Unfurnished Bareshell Office for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>90,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 4800 sq.ft</span></li>
                                            <li><span>Maintenance - <i><MdOutlineCurrencyRupee /></i>2 per sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 mb-lg-0 active">
                                <div className="price__inner p-relative">
                                    <p>Fully Furnished Office for Rent in Raipur</p>
                                    <span>Location - 3<sup>rd</sup> Floor, Office No A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>2,00,000/-</span></li>
                                            <li><span>Security Deposit - 5 months</span></li>
                                            <li><span>Built-up Area - 6000 sq.ft</span></li>
                                            <li><span>Maintenance - <i><MdOutlineCurrencyRupee /></i>3 per sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix active">
                                <div className="price__inner p-relative">
                                    <p>Fully Furnished Private Office for Rent in Raipur - Budget Friendly</p>
                                    <span>Location - 2<sup>nd</sup> Floor, Rio Mall, Lalpur, Raipur, Chhattisgarh 492001</span>
                                    <p style={{ height: '34px' }} className="mb-0 d-lg-block d-none"></p>
                                    <div className="price__features mt-20 mb-30">
                                        <b className="d-block mb-10">Highlights</b>
                                        <ul>
                                            <li><span>Fully Furnished Private Office Space for Rent</span></li>
                                            <li><span>Rental - <i><MdOutlineCurrencyRupee /></i>45,000/-</span></li>
                                            <li><span>Security Deposit - 4 months</span></li>
                                            <li><span>Built-up Area - 1872 sq.ft</span></li>
                                        </ul>
                                    </div>
                                    <Link
                                        to="/"
                                        className="price-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            document.getElementById('clickFrom').click();
                                        }}
                                    >
                                        Know More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PlanSection;